var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "ma-0 pa-0",
      attrs: { fluid: "" },
    },
    [
      _c("v-data-table", {
        staticClass: "rounded-0",
        class: _vm.table,
        attrs: {
          headers: _vm.headers,
          height: _vm.tableHeight,
          items: _vm.items,
          "loading-text": "Retrieving Existing Data...Please Wait",
          search: _vm.search,
          loading: _vm.loading,
          "group-by": "promo_item_group.id",
          dense: "",
          "fixed-header": "",
          "hide-default-footer": "",
          "item-key": "id",
          "loader-height": "5",
          "no-data-text": "No Data to Display",
          "items-per-page": _vm.pageSize,
        },
        scopedSlots: _vm._u(
          [
            {
              key: `group.header`,
              fn: function ({ group, items, headers }) {
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "truncate" }, [
                            _c("span", [
                              _c("b", [_vm._v("Promo Item Group")]),
                              _c("br"),
                              _c("div", { staticClass: "truncate" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(items[0].promo_item_group.name) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("v-col", { staticClass: "truncate" }, [
                            _c("span", [
                              _c("b", [_vm._v("Promo Name")]),
                              _c("br"),
                              _vm._v(" " + _vm._s(items[0].promo.name) + " "),
                            ]),
                          ]),
                          _c("v-col", [
                            _c("span", [
                              _c("b", [_vm._v("Promo Type")]),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(items[0].promo.promotion_type.name) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("v-col", [
                            _c("span", [
                              _c("b", [_vm._v("Promo Start Date")]),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDateDisplay(
                                      items[0].promo.start_dt
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("v-col", [
                            _c("span", [
                              _c("b", [_vm._v("Promo End Date")]),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDateDisplay(items[0].promo.end_dt)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          items[0].promo_item_group.buy_item
                            ? _c("v-col", [
                                _c(
                                  "span",
                                  [
                                    _c("b", [_vm._v("Buy Item?")]),
                                    _c("br"),
                                    _c("v-icon", { attrs: { dense: "" } }, [
                                      _vm._v("mdi-check-bold"),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          items[0].promo_item_group.get_item
                            ? _c("v-col", [
                                _c(
                                  "span",
                                  [
                                    _c("b", [_vm._v("Get Item?")]),
                                    _c("br"),
                                    _c("v-icon", { attrs: { dense: "" } }, [
                                      _vm._v("mdi-check-bold"),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          items[0].promo_item_group.get_item ||
                          items[0].promo_item_group.buy_item
                            ? _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Qty")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(items[0].promo_item_group.qty) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("v-col", [
                            _c("span", [
                              _c("b", [_vm._v("Billing Mode")]),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(items[0].promo.billing_mode.name) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { align: "right" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-6",
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    color:
                                                      _vm.feature.promo_item_group_ids.includes(
                                                        group
                                                      )
                                                        ? "red"
                                                        : "green",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.feature.promo_item_group_ids.includes(
                                                        group
                                                      )
                                                        ? _vm.unlinkPromo(group)
                                                        : _vm.linkPromo(
                                                            items[0]
                                                          )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.feature.promo_item_group_ids.includes(
                                                          group
                                                        )
                                                          ? "mdi-trash-can"
                                                          : "mdi-plus"
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.feature.promo_item_group_ids.includes(
                                          group
                                        )
                                          ? "Remove"
                                          : "Add"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openLink(
                                                        items[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(" mdi-open-in-new "),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Promotion Portal")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.promotion_price`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.promotion_price
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.promotion_price
                              )
                            ) +
                            " "
                        ),
                      ])
                    : item.promo.promotion_price
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo.promotion_price
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.rebate_amount`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.rebate_amount
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.rebate_amount
                              )
                            ) +
                            " "
                        ),
                      ])
                    : item.promo.rebate_amount
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo.rebate_amount
                              )
                            )
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.billback_allowance`,
              fn: function ({ item }) {
                return [
                  item.accrual_amt
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(item.accrual_amt)
                            ) +
                            " "
                        ),
                      ])
                    : item.promo_item_group.billback_allowance
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.billback_allowance
                              )
                            ) +
                            " "
                        ),
                      ])
                    : item.promo.billback_allowance
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo.billback_allowance
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.amap_allowance`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.amap_allowance
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.amap_allowance
                              )
                            ) +
                            " "
                        ),
                      ])
                    : item.promo.amap_allowance
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo.amap_allowance
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.early_buy_allowance`,
              fn: function ({ item }) {
                return [
                  item.promo_item_group.early_buy_allowance
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo_item_group.early_buy_allowance
                              )
                            ) +
                            " "
                        ),
                      ])
                    : item.promo.early_buy_allowance
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.promo.early_buy_allowance
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.retail_price`,
              fn: function ({ item }) {
                return [
                  item.retail_price
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(item.retail_price)
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.cost`,
              fn: function ({ item }) {
                return [
                  item.item.future_cost == item.item.cost &&
                  item.item.future_cost_date <= _vm.selectedAd.ad_end_date
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "primary--text font-weight-bold",
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " $" +
                                            _vm._s(
                                              _vm.formatCurrencyDisplay(
                                                item.item.cost
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(
                            " This item has a cost change on " +
                              _vm._s(
                                _vm.$dateConfig.formatDate(
                                  item.item.future_cost_date
                                )
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " The new cost of $" +
                              _vm._s(
                                _vm.formatCurrencyDisplay(item.item.cost)
                              ) +
                              " will be used on this ad "
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(_vm.formatCurrencyDisplay(item.cost)) +
                            " "
                        ),
                      ]),
                ]
              },
            },
            {
              key: `item.item.unit_cost`,
              fn: function ({ item }) {
                return [
                  item.item.qty_per_case
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(_vm.calcUnitCost(item))
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [_vm._v(" - ")]),
                ]
              },
            },
            {
              key: `item.item.gross_profit`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatCurrencyDisplay(_vm.calcGrossProfit(item))
                      ) +
                      "% "
                  ),
                ]
              },
            },
            {
              key: `item.start_dt`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.formatDateDisplay(item.start_dt)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.end_dt`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.formatDateDisplay(item.end_dt)) + " "
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }